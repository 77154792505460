import _ from 'lodash'
import PermissionModel from '@/Modules/Permission/PermissionModel.js'

export default class DocumentTypeModel {
  id = null
  name = null
  tag = null
  permissionId = null
  allowedVersions = null
  permission = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value

      this.permission = data.permission && new PermissionModel(data.permission)
    })
  }
}
